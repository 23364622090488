import React, { useEffect, useRef, useState } from 'react';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import {
  faArrowLeftLong,
  faArrowRightFromBracket,
  faCircleCheck,
  faCircleExclamation,
  faDownload,
  faFileExport,
  faRotateForward,
  faSync,
  faUpRightAndDownLeftFromCenter,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Delivery } from '@/app/stores/deliverySlice';
import { Table } from '@/features/sharedComponents/Table/Table';

import DeliveryCardModal from '../deliveryCardModal/DeliveryCardModal';
import { deliveryColTable } from './deliveryColTab';

// Styles
import styles from './DeliveryTab.module.scss';
import { IconButton } from '@/features/sharedComponents/Buttons/iconButton/IconButton';
import { useGetOrdersQuery, useGetPlannedDeliveryQuery } from '@/features/api/copApiEndpoints/orderAPI';
import { ordersAPI } from '@/features/api/copApiAdapters/copAdapterAPI';
import { useAppSelector } from '@/app/hooks';
import { Button, CircularProgress, Snackbar } from '@mui/joy';
import exportToCsv from '@/features/functions/exportToCsv';
import { PayloadReturn } from '@/features/api/copApiEndpoints/payloadAPI';
import exportToXls from '@/features/functions/exportToXls';

export type DeliveryTabProps = {
  title: string;
  dataSet: Delivery[] | undefined;
  height?: string;
  handleExpand?: () => void;
  handleShrink?: () => void;
};

export function DeliveryTab(props: DeliveryTabProps) {
  const { title, dataSet, height, handleExpand, handleShrink } = props;

  // Get component width dynamically
  const ref = useRef<HTMLDivElement>(null);
  const [w, setW] = useState(0);
  const [hoverRowId, setHoverRowId] = useState<string | null>(null);
  const [delivery, setDelivery] = useState<Delivery | null>(null);
  const [isShowDeliveryModal, setIsShowDeliveryModal] = useState<boolean>(false);
  // Refetch data onClick
  const {data, isLoading, refetch: plannedDelRefetch, isSuccess: plannedDelSucc, isError: plannedDelErr } = useGetPlannedDeliveryQuery({})

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setW(ref.current!.offsetWidth);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Export data to CSV
  const flattenNestedPayload = (obj: PayloadReturn) => {
    // Example implementation, you may need to adjust it based on your actual data structure
    return obj.items.map(item => `${item.product.name}: ${item.containerType.name}: ${item.amount}`).join(' - ');
    // id: string;
    // mass: number;
    // validated: boolean;
    // items: {
    //     id: number;
    //     amount: number;
    //     mass: number;
    //     product: {
    //         density: number;
    //         id: string;
    //         name: string;
    //         unNumber: string;
    //         properShippingName: string;
    //         hazards: string;
    //         packingGroup: string;
    //         verified: boolean;
    //     };
    //     containerType: container;
    // }[];
  };
  const handleExport = () => {
    // Extracting data from the DataGrid
    if (dataSet != undefined) {
      const dataHeaderToExport = ['ID', 'Reference', 'Ordered By', 'Prepared By', 'Unloaded By', 'Departure', 'Arrival', 'Payload',  'ETR', 'ETA', 'Status'];
      const dataContentToExport = dataSet.map(row => ({
        id: row.id,
        ref: row.no,
        ordered_by: row.ordered_by,
        prepared_by: row.prepared_by,
        unloaded_by: row.unloaded_by,
        departure: row.departure,
        arrival: row.arrival,
        payload: flattenNestedPayload(row.payload), 
        etr: row.etr,
        eta: row.eta,
        status: row.status,
      }));
      // Perform export operation (e.g., export to CSV)
      exportToXls(dataHeaderToExport, dataContentToExport);
    }
  };

  // Define Table props
  const CustomToolbar:
    | React.JSXElementConstructor<any>
    | null
    | undefined = () => {
    return (
      <div>
        <GridToolbarContainer className={styles.toolbar_wrapper}>
          {/* <GridToolbarColumnsButton/>  */}
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              {height && 
                <IconButton 
                  content='' 
                  icon={faArrowRightFromBracket} 
                  onClick={() => (handleShrink ? handleShrink() : () => {})}
                  color='unset_blue'
                  rotate='rotate180'
                  noBorder
                />
              }
              <div style={{marginRight: '40px'}}>{title}</div>
            </div>
            <>
            {height && <>
              {title === 'Planned deliveries' && (
                <IconButton 
                  content='Sync' 
                  icon={faSync} 
                  onClick={() => {plannedDelRefetch()}}
                  color='blue'
                  rotate='rotate180'
                  noBorder
                />
              )}
              <IconButton 
                content='Export' 
                icon={faDownload} 
                onClick={() => {handleExport()}}
                color='blue'
                noBorder
              />
            </>}
            </>
          </div>
          {height && <GridToolbarQuickFilter />}
        </GridToolbarContainer>
      </div>
    );
  };

  const handleChooseDelivery = (event: React.MouseEvent<HTMLDivElement>) => {
    const rowId = event.currentTarget.dataset.id as string;
    const delivery = dataSet?.find((del) => del.id === rowId);
    if (delivery) {
      setDelivery(delivery);
      setIsShowDeliveryModal(true);
    }
  };

  const handleClose = () => {
    setIsShowDeliveryModal(false);
    setDelivery(null);
  };

  const handleMouseEnterRow = (event: React.MouseEvent<HTMLDivElement>) => {
    const rowId = event.currentTarget.dataset.id as string;
    // setHoverRowId(rowId);
  };

  const handleMouseLeaveRow = (event: React.MouseEvent<HTMLDivElement>) => {
    setHoverRowId(null);
  };

  return (
    <div ref={ref} className={styles.card_table}>
      <Table
        columns={deliveryColTable({ props_width: w, hoverRowId: hoverRowId })}
        data={dataSet}
        colTools
        toolbar={CustomToolbar}
        tableHeight={height || '400px'}
        initState={title == 'Planned deliveries' ? [{ field: 'eta', sort: 'asc' }] : [{ field: 'eta', sort: 'desc' }]}
        handleMouseEnterRow={handleMouseEnterRow}
        handleMouseLeaveRow={handleMouseLeaveRow}
        handleChooseRow={handleChooseDelivery}
      />
      {!height ? ( // IF NOT EXPANDED
        <div style={{marginLeft: '-10px'}}>
          <IconButton 
            content='' 
            icon={faUpRightAndDownLeftFromCenter} 
            onClick={() => (handleExpand ? handleExpand() : () => {})}
            color='unset_blue'
            noBorder
          />
        </div>
      ) : null}
      {delivery && (
        <DeliveryCardModal
          delivery={delivery}
          open={isShowDeliveryModal}
          handleClose={handleClose}
        />
      )}
    </div>
  );
}
