import * as XLSX from 'xlsx';

function exportToXls(header: string[], data: object[]) {
  // Create a worksheet from the data
  const ws = XLSX.utils.aoa_to_sheet([
    header,
    ...data.map(row => Object.values(row))
  ]);

  // Create a new workbook and append the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Deliveries');

  // Generate the file name with the current date
  const currentDate = new Date().toISOString().slice(0, 10); // Format: YYYY-MM-DD
  const fileName = `adlc_deliveries_${currentDate}.xlsx`;

  // Write the workbook and trigger download
  XLSX.writeFile(wb, fileName);
}

export default exportToXls;
